<template>
  <section>
    <Navbar :theme="isMainTheme ? 'mains' : $store.state.Index.isDarkMode ? 'dark'  : 'light'" />
    <main class="main">
      <router-view></router-view>
    </main>
    <Footer />
    <notifications position="top right" />
  </section>
</template>

<script>
import Navbar from '../components/main/Navbar';
import Footer from '../components/main/Footer';
import '/public/bootstrap.css';

export default {
  name: 'defaultLayout',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      isDarkThemed: false,
      isMainTheme: false
    }
  },
  watch:{
    $route (to, from){
      this.isMainTheme = to.name === 'Index';
    }
  },
  mounted() {
    this.isMainTheme = this.$route.name === 'Index';
  }
}
</script>

<style scoped>

.main {
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 40px);
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
